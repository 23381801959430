import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["count", "input"];

  connect() {
    this.count = !this.inputTarget.value || isNaN(this.inputTarget.value) || this.inputTarget.value == 0 ? 1 : parseInt(this.inputTarget.value);
    this.countTarget.textContent = this.count;
    this.inputTarget.value = this.count;
  }

  increment(event) {
    event.preventDefault();
    this.count++;
    this.updateAmount();
  }

  decrement(event) {
    event.preventDefault();
    if (this.count > 1) {
      this.count--;
      this.updateAmount();
    }
  }

  updateAmount() {
    this.count = !this.inputTarget.value || isNaN(this.count) || this.count == 0 ? 1 : this.count; // Check for NaN
    this.countTarget.textContent = this.count;
    this.inputTarget.value = this.count;
  }
}