import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["source"];

  copy() {
    navigator.clipboard
      .writeText(this.sourceTarget.getAttribute("data-clipboard-text"))
      .then(() => {
        this.sourceTarget.textContent = "Kopierad!"; // Change the button text on success
      })
      .catch((err) => {
        console.error("Error copying text: ", err);
      });
  }
}
