import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["button"];

  initialize () {
    console.log("sustainable_item#initialize")
  }

  create (e) {
    e.preventDefault()
    console.log("sustainable_item#create")
  }

  remove (e) {
    e.preventDefault()
    console.log("sustainable_item#remove")
  }
}