import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    this.flash(5000); // Hide the flash after 5 seconds
  }

  flash(duration) {
    this.slideIn();

    setTimeout(() => {
      this.slideOut();
    }, duration);
  }

  slideIn() {
    requestAnimationFrame(() => {
      this.element.style.transform = 'translateY(0)';
      this.element.style.opacity = 1;
    });
  }

  slideOut() {
    this.element.style.transform = 'translateY(100%)';
    this.element.style.opacity = 0;
  }

  dismiss() {
    this.slideOut();
  }
}