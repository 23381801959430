import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["inputField", "results", "hiddenField"];

  connect() {
    console.log("connected", this.targets.element);
    this.inputFieldTarget.addEventListener("input", () => {
      this.search();
    });

    this.inputFieldTarget.addEventListener("click", () => {
      this.search();
    });

    document.addEventListener("click", (event) => {
      const resultsContainer = document.querySelector(
        "#results-" + this.inputFieldTarget.dataset.id
      );
      if (resultsContainer && !resultsContainer.contains(event.target)) {
        resultsContainer.outerHTML = "";
      }
    });
  }

  async search(e) {
    const query = this.inputFieldTarget.value;
    const id = this.inputFieldTarget.dataset.id;
    const result = await fetch(`/produkter/search?query=${query}`);
    const products = await result.json();

    console.log(products);

    const resultsContainer = this.resultsTarget;
    resultsContainer.innerHTML = "";

    const ul = document.createElement("ul");
    ul.id = "results-" + id;
    ul.classList.add(
      "absolute",
      "z-10",
      "mt-1",
      "max-h-[200px]",
      "md:max-h-[300px]",
      "max-w-[90vw]",
      "overflow-auto",
      "rounded-md",
      "bg-white",
      "py-1",
      "text-base",
      "shadow-lg",
      "ring-1",
      "ring-black",
      "ring-opacity-5",
      "focus:outline-none",
      "sm:text-sm"
    );

    if (products.length === 0) {
      const li = document.createElement("li");
      li.classList.add(
        "relative",
        "cursor-default",
        "select-none",
        "py-2",
        "pl-3",
        "pr-3",
        "text-gray-400"
      );
      li.id = "option-0";
      li.setAttribute("role", "option");
      li.setAttribute("tabindex", "-1");

      const span1 = document.createElement("span");
      span1.classList.add("block", "truncate");
      span1.innerText = "Inget resultat";

      li.appendChild(span1);
      ul.appendChild(li);
    }

    products.forEach((product) => {
      const li = document.createElement("li");
      li.classList.add(
        "relative",
        "cursor-default",
        "select-none",
        "py-2",
        "pl-3",
        "pr-3",
        "text-gray-900",
        "hover:bg-primary-600",
        "hover:text-white",
        "flex",
        "flex-row",
        "flex-wrap",
        "items-center",
        "gap-x-2",
        "gap-y-1"
      );
      li.id = "option-0";
      li.setAttribute("role", "option");
      li.setAttribute("tabindex", "-1");

      const div1 = document.createElement("div");
      div1.classList.add("block", "truncate", "mr-auto");
      div1.innerText = product.title;

      li.appendChild(div1);
      // Create badges dynamically based on product tags
      if (product.tag_list && product.tag_list.length > 0) {
        product.tag_list.forEach((tag) => {
          const badge = document.createElement("div");
          badge.classList.add(
            "bg-secondary-500",
            "text-black",
            "rounded-full",
            "text-xs",
            "flex",
            "px-2",
            "py-1",
            "items-center",
            "whitespace-nowrap"
          );
          const p = document.createElement("p");
          p.innerText = tag;
          badge.appendChild(p);
          li.appendChild(badge); // Append each badge next to the title
        });
      }

      li.addEventListener("click", () => {
        console.log("clicked", product);
        this.inputFieldTarget.value = product.title;
        // this.hiddenFieldTarget.value = product.id;
        resultsContainer.innerHTML = "";
      });

      ul.appendChild(li);
    });

    resultsContainer.appendChild(ul);
  }
}
