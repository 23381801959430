import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input", "options", "option", "noResults", "tagList"];

  connect() {
    this.selectedTags = [];
    document.addEventListener("click", this.closeList.bind(this));

    // Grab the existing tags from the input field
    const tags = this.inputTarget.value.split(",").filter((tag) => tag !== "");
    tags.forEach((tag) => {
      this.selectedTags.push(tag.trim());
    });

    console.log(tags);

    // Show the checkmark for each selected tag
    this.inputTarget.value = this.selectedTags.join(", ");

    this.optionTargets.forEach((option) => {
      const tag = option.textContent.trim();
      if (this.selectedTags.includes(tag)) {
        option.querySelector(".checkmark").style.display = "block";
      }
    });
  }

  disconnect() {
    document.removeEventListener("click", this.closeList.bind(this));
  }

  openList() {
    this.optionsTarget.style.display = "block";
    if (this.optionTargets.every((option) => option.style.display === "none")) {
      this.noResultsTarget.style.display = "block";
    }
  }

  search(event) {
    const query = event.target.value.toLowerCase();
    let hasVisibleOptions = false;

    this.optionTargets.forEach((option) => {
      const tag = option.textContent.toLowerCase();
      if (tag.includes(query)) {
        option.style.display = "block";
        hasVisibleOptions = true;
      } else {
        option.style.display = "none";
      }
    });

    if (hasVisibleOptions) {
      this.noResultsTarget.style.display = "none";
    } else {
      this.noResultsTarget.style.display = "block";
    }

    this.optionsTarget.style.display = "block"; // Show the list when searching
  }

  selectOption(event) {
    event.stopPropagation();
    const tag = event.currentTarget.textContent.trim();
    const checkmark = event.currentTarget.querySelector(".checkmark");

    if (!this.selectedTags.includes(tag)) {
      this.selectedTags.push(tag);
      checkmark.style.display = "block"; // Show the checkmark
    } else {
      const index = this.selectedTags.indexOf(tag);
      if (index > -1) {
        this.selectedTags.splice(index, 1);
        checkmark.style.display = "none"; // Hide the checkmark
      }
    }

    this.updateSelectedTags();
  }

  updateSelectedTags() {
    this.inputTarget.value = this.selectedTags.join(", ");
  }

  closeList(event) {
    if (!this.element.contains(event.target)) {
      this.optionsTarget.style.display = "none";
    }
  }
}
