import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="combobox"
export default class extends Controller {
  static targets = ["input", "list", "badges"]

  selectedItems = []

  connect() {
    this.hideList()
    this.inputTarget.addEventListener("input", () => this.filterList())

    // Close the list when clicking outside of the combobox element
    document.addEventListener("click", (event) => {
      if (!this.element.contains(event.target)) {
        this.hideList(); 
      }
    })
  }

  clearInput() {
    this.inputTarget.value = ""
    this.filterList()
  }

  selectItem(event) {
    const selectedItem = event.currentTarget.innerText
    this.selectedItems.push(selectedItem)

    this.hideList()
    this.renderBadges()
    this.clearInput()
  }

  showList() {
    this.listTarget.style.display = "block"
  }

  hideList() {
    this.listTarget.style.display = "none"
  }

  hideBadge(name) {
    const badge = this.badgesTarget.querySelector(`[data-item-name="${name}"]`)
    badge.classList.add("hidden")
  }

  removeFromList(event) {
    const itemName = event.currentTarget.dataset.itemName
    this.selectedItems = this.selectedItems.filter(item => item !== itemName)
    this.hideBadge(itemName)
  }

  renderBadges() {
    Array.from(this.badgesTarget.children).forEach(badge => {
      const name = badge.dataset.itemName
      console.log(name)
      if (this.selectedItems.includes(name)) {
        badge.classList.remove("hidden")
      }
    })
  }

  toggleList() {
    if (this.listTarget.style.display === "none" || this.listTarget.style.display === "") {
      this.showList()
    } else {
      this.hideList()
    }
  }

  filterList() {
    const query = this.inputTarget.value.toLowerCase()
    const listItems = Array.from(this.listTarget.children)
    
    listItems.forEach(item => {
      const itemName = item.querySelector(".truncate").textContent.toLowerCase()
      if (itemName.includes(query)) {
        item.style.display = "block"
      } else {
        item.style.display = "none"
      }
    })
  }

  disconnect() {
    // Remove the document click event listener when the controller disconnects
    document.removeEventListener("click", this.boundDocumentClick)
  }
}